<div class="dashboard">
  <div fxLayout="column">
    <div fxFlex class="title">
      <a href="">Dashboard</a>
    </div>
    <div fxFlex class="main-content">Main Content</div>

    <div fxLayout="row wrap" fxLayoutAlign=" stretch" fxLayoutGap.gt-sm="1rem">
      <div fxFlex fxFlex.lt-md="100" class="secondary-content">Secondary Content</div>
      <div fxFlex fxFlex.lt-md="100" fxLayout="row wrap">
        <div fxFlex="100" fxLayout="row wrap" fxLayoutGap.gt-xs="1rem">
          <div class="box-1" fxFlex fxFlex.xs="100">
            <p>Abstract Content</p>
          </div>
          <div class="box-2" fxFlex fxFlex.xs="100">
            <p>+</p>
          </div>
        </div>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutGap.gt-xs="1rem">
          <div class="box-2" fxFlex fxFlex.xs="100">
            <p>+</p>
          </div>
          <div class="box-2" fxFlex fxFlex.xs="100">
            <p>+</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>