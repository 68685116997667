import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ToasterService } from './shared/toaster.service';
import { Router } from '@angular/router';
import { CommonService } from './shared/common.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private toaster: ToasterService, private route: Router, private commonService: CommonService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = sessionStorage.getItem('accessToken') ? sessionStorage.getItem('accessToken') : '';
    if (token) {
      const clonedRequest = request.clone({
        headers: request.headers.set("Authorization", "Bearer " + token)
      });
      return next.handle(clonedRequest).pipe(
        tap(evt => this.success(evt)),
        catchError((e: any) => {
          return this.error(e);
        })
      )
    } else {
      return next.handle(request).pipe(
        tap(evt => this.success(evt)),
        catchError((e: any) => {
          return this.error(e);
        })
      )
    }

  }
  success(evt: any) {
    if (evt.status == 200 || evt.status == 201) {
      let response = evt.body;
      if (response.Status == 200 || response.Status == 201) {
        this.toaster.show('success', 'success!', response.message);
        // console.log(response.message);
      } else if (response.message) {
        this.toaster.show('success', 'success!', response.message);
      }
    }
    // console.log(evt, "Auth interceptor Success");
  }
  error(e: any) {
    let err = e.error;
    let errMsg = 'something went wrong';
    if (err) {
      if (err.error) { errMsg = err.error }
      else if (err.message) { errMsg = err.message }
      else{
        Object.keys(err).map((key) => { 
          errMsg = err[key]
        })
      }
    }
    if (e.status == 401 && err.code == "token_not_valid") {
      this.toaster.show('warning', '', 'Session Expired! Please login again.');
      sessionStorage.clear();
      this.commonService.spin$.next(false);
      this.route.navigate(['login']);
    }
    console.log(e, "Error from interceptor")
    if (e.status == 400) {
      this.toaster.show('warning', errMsg, '');
    }
    if (e.status == 404) {
      this.toaster.show('warning', 'The page you are looking for is not found or deleted.', '');
      this.commonService.spin$.next(false);
      // this.route.navigate(['admin/dashboard']);
    }
    if(e.status == 403){
      this.toaster.show('warning', err.detail, '');
      this.commonService.spin$.next(false);
      // this.route.navigate(['admin/dashboard']);
    }
    // this.toaster.show('warning', errMsg, '');
    return throwError(errMsg);
  }
}
