import { Component, OnInit, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  template: `<h1 mat-dialog-title>{{data.title}}</h1>
  <div mat-dialog-content>
    {{data.message}}
  </div>
  <div style="float:right;margin:20px;">
    <button style="margin:0px 10px;cursor:pointer;" mat-raised-button mat-button color="primary" type="button" value="Confirm" [mat-dialog-close]="true">Confirm</button>
    <button type="button" style="cursor:pointer;" mat-raised-button mat-button color="secondary" value="Cancel" [mat-dialog-close]="false">Cancel</button>
  </div>
  `
})
export class ConfirmDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
