import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
    selector: 'app-confirm-dialog',
    template: `<button mat-button class="close-icon" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
</button>
<button mat-button align="right" color="primary" (click)="print()">Print</button>
<ngx-barcode6 [bc-format]="selectedBarCodeType" [bc-width]="selectedWidth"
        [bc-height]="selectedHeight" [bc-font-size]="selectedFontSize" [bc-value]="data.code" [bc-display-value]="true"
        id="code"></ngx-barcode6>`
})
export class barCodeDialogComponent implements OnInit {
    @ViewChild('elRef') elRef;
    barCodeTypes: any = [
        "CODE128",
        "CODE128A",
        "CODE128B",
        "CODE128C",
        "EAN13",
        "UPC",
        "EAN8",
        "EAN5",
        "EAN2",
        "CODE39",
        "ITF14",
        "MSI",
        "MSI10",
        "MSI11",
        "MSI1010",
        "MSI1110",
        "pharmacode",
        "codabar"
    ]
    widths = [0.25, 1, 2, 3, 4];
    heights = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 120, 140, 160, 180, 200];
    fontSizes = [10, 12, 14, 16, 18, 20, 22, 24, 28, 32, 36, 40, 44, 48];
    selectedWidth = this.widths[2];
    selectedHeight = this.heights[4];
    selectedFontSize = this.fontSizes[3];
    selectedBarCodeType = this.barCodeTypes[0];
    constructor(public dialogRef: MatDialogRef<barCodeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {

    }
    print() {
        const printContent = document.getElementById("code");
        const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
        WindowPrt.document.write(printContent.innerHTML);
        WindowPrt.document.close();
        WindowPrt.focus();
        WindowPrt.print();
        WindowPrt.close();
    }

}