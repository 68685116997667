import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    template: ` <button mat-button class="close-icon" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
</button>
    <h1 mat-dialog-title>{{data.title}}</h1>
  <div mat-dialog-content>
    <img src="{{data.imagePath}}" alt="{{data.title}}"/>
  </div>
  `
})
export class ImageDialogComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<ImageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
    }

}
