<section fxLayout="column wrap" fxLayoutGap="60px" fxLayoutAlign="center center">
    <div fxFlex>
        404 Work In Progress...
    </div>
    <div fxFlex>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <!-- <div fxFlex>
        ... But we can not find it.
    </div> -->
</section>